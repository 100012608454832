import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';

const MealPreferences = () => {
    const navigate = useNavigate();
    const mealkitClickHandler = () => {
        navigate('/select-day');
    };
    return (
        <>
            <Header />
            <div className='mealpref-wapper'>
                <div className='page-title'>How would you like to explore the menu?</div>
                {/* <div className='subtitle'>
                    View weekly <span>Menu</span>
                </div> */}
                {/* <div className='menu-type-container'>
                    <div className='menu-type viewonly' onClick={mealkitClickHandler}>
                        <div className='icon'></div>
                        <div className='menu-type-title'>View Only Menus</div>
                        <div className='menu-type-desp'>
                            The list of menu items will be in a view only mode.
                            you can see the mealkit for item but can't order
                            them.
                        </div>
                    </div>
                    <div className='menu-type transactional' onClick={mealkitClickHandler}>
                        <div className='icon'></div>
                        <div className='menu-type-title'>Transactional Menus</div>
                        <div className='menu-type-desp'>
                            The list of menu items will be in a transactional only mode.
                            you can order the mealkit of all the items.
                        </div>
                    </div>
                </div> */}
                <div className='mealpref-container'>
                    <div
                        className='mealkit-container indian'
                        onClick={mealkitClickHandler}
                    >
                        <div className='mealkit-name'>
                            Indian <br /> meal kit
                        </div>
                    </div>
                    <div
                        className='mealkit-container continental'
                        onClick={mealkitClickHandler}
                    >
                        <div className='mealkit-name '>
                            Continental <br /> meal kit
                        </div>
                    </div>
                    <div
                        className='mealkit-container oriental'
                        onClick={mealkitClickHandler}
                    >
                        <div className='mealkit-name '>
                            Oriental <br /> meal kit
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MealPreferences;
