import React, { useState } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { VIEWONLY1, VIEWONLY2 } from '../typecodes';
import Alergy1 from '../assets/alergy/allergen.png';
import Alerg2 from '../assets/alergy/allergy.png';
import Alerg4 from '../assets/alergy/gluten-free.png';
import Alerg5 from '../assets/alergy/no-milk.png';
import { generateWeekDays } from '../helpers/_commonHelper';
import { useNavigate } from 'react-router-dom';
const ViewOnlyMenu = () => {
    const list = generateWeekDays();
    const [daysList, setDaysList] = useState(list);
    const [selectedDate, setSelectedDate] = useState(list[0]?.Date || '');
    const navigate = useNavigate();

    const backClickHandler = () => {
        navigate('/');
    };
    return (
        <div className='view-only-container'>
            <div className='back-btn' onClick={backClickHandler}>
                <ArrowLeftOutlined />
            </div>
            <div className='menu-title'>Regular Menu</div>
            <div className='days_wrapper'>
                {daysList.map((day) => {
                    return (
                        <div
                            className={
                                'day ' +
                                (day.Date === selectedDate ? 'active' : '')
                            }
                            onClick={() => setSelectedDate(day.Date)}
                        >
                            {day.dayShortName}
                            {/* <div className='date'>{day.Date}</div> */}
                        </div>
                    );
                })}
            </div>
            <div className='menu-content'>
                <div className='menu-content-container'>
                    {VIEWONLY1.map((category,index) => (
                        <>
                            <div className='view-only-category'>
                               <span className='count'>{index+1}</span>{category.name}
                            </div>
                            {category?.without_sub_category_items.map(
                                (item) => (
                                    <div className='view-only-item-wrapper'>
                                        <div className='name-wrapper'>
                                            <span className='name'>{item.name}</span>
                                            <span className='line'></span>
                                        </div>
                                        <div
                                            className='description'
                                            dangerouslySetInnerHTML={{
                                                __html: item.description,
                                            }}
                                        ></div>
                                        <div className='food-alergy'>
                                            <img
                                                src={Alergy1}
                                                alt='icon'
                                                className='alergy-icon'
                                            />
                                            <img
                                                src={Alerg2}
                                                alt='icon'
                                                className='alergy-icon'
                                            />
                                            <img
                                                src={Alerg4}
                                                alt='icon'
                                                className='alergy-icon'
                                            />
                                            <img
                                                src={Alerg5}
                                                alt='icon'
                                                className='alergy-icon'
                                            />
                                        </div>
                                    </div>
                                )
                            )}
                        </>
                    ))}
                   
                </div>
            </div>
          
        </div>
    );
};

export default ViewOnlyMenu;
