const weekSet = {
    0: 'Sun',
    1: 'Mon',
    2: 'Tue',
    3: 'Wed',
    4: 'Thu',
    5: 'Fri',
    6: 'Sat',
};
export const generateWeekDays = () => {
    const dateArr = [];
    for (let i = 0; i < 6; i++) {
        let today = new Date();
        const todatData = new Date(today.setDate(today.getDate() + i));
        const obj = {
            dayShortName: weekSet[todatData.getDay()],
            Date: todatData.getDate(),
        };
        dateArr.push(obj);
    }
    return dateArr;
};