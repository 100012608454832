import { ArrowLeftOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Drawer } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const backButtonClickHandler = () => {
        navigate(-1);
    };
    const logoClickHandler = () => {
        navigate('/', { replace: true });
    };
    return (
        <>
            <div className='header'>
                <div className='back-icon' onClick={backButtonClickHandler}>
                    <ArrowLeftOutlined />
                </div>
                <div className='header-logo' onClick={logoClickHandler}></div>
                <div className='info-icon'>
                    <InfoCircleOutlined
                        onClick={() => setOpen((prevState) => !prevState)}
                    />
                </div>
            </div>
            <Drawer
                title='Information '
                placement={'bottom'}
                closable={false}
                onClose={() => setOpen(false)}
                open={open}
                key={'bottom'}
                height={'250px'}
            >
                If you have any concerns regarding food allergies, please alert
                your server prior to ordering Prices are in Indian Rupees and
                applicable to Government Taxes We do not levy any service charge
            </Drawer>
        </>
    );
};

export default Header;
