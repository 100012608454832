import { Button, Drawer, Input } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import ItemCard from '../components/ItemCard';
import ReviewItemCard from '../components/ReviewItemCard';
import { DINNER } from '../typecodes';

const ReviewOrder = () => {
    const [value, setValue] = useState('');
    const [open, setOpen] = useState(false);


    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])
    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const navigate = useNavigate();
    const redirectToReview = () => {
        navigate('/select-day');
    };

    return (
        <>
            <Header />
            <div className='review-order-wraper'>
                <div className='page-title'>Review Order</div>
                <div className='review-order-container'>
                    {DINNER[0].without_sub_category_items.map((item) => (
                        <ItemCard menuItem={item} showCounter />
                    ))}
                </div>
                <div className='add-morebtn-wrapper'>
                    <Button
                        type='primary'
                        shape='round'
                        size='medium'
                        style={{ backgroundColor: '#1d1e22', color: '#b59a63' }}
                        onClick={redirectToReview}
                    >
                        Add more items
                    </Button>
                </div>
                <div className='price-details'>
                    <div class='price__item total'>
                        <span class='label'>Item Total</span>
                        <span class='val'>₹550.00</span>
                    </div>
                    <div class='price__item tax'>
                        <span class='label'>Total Taxes</span>
                        <span class='val val-grey'>₹209.00</span>
                    </div>
                    <div class='price__item grand-pay'>
                        <span class='label'>Grand Total</span>
                        <span class='val'>₹759.00</span>
                    </div>
                </div>
                <div className='add-details'>
                    <div className='title'>
                        Ente your details <br />
                        <small>
                            <small>
                                (These details are just to identify you while
                                serving the food)
                            </small>
                        </small>
                    </div>
                    <div className='form-input'>
                        <Input placeholder='Enter name' size='large' />
                    </div>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <div className='form-input'>
                            <Input
                                placeholder='Enter Employee ID'
                                size='large'
                            />
                        </div>
                        <div className='form-input'>
                            <Input
                                placeholder='Enter mobile no.'
                                size='large'
                            />
                        </div>
                    </div>

                    <div className='form-input'>
                        <TextArea
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                            placeholder='Add cooking instructions'
                            autoSize={{ minRows: 3, maxRows: 5 }}
                        />
                    </div>
                </div>
            </div>
            <div className='review-wrapper'>
                <Button
                    type='primary'
                    style={{ backgroundColor: '#1d1e22', color: '#2e3d62' }}
                    onClick={() => navigate('/', { replace: true })}
                    shape='round'
                >
                    Reset
                </Button>
                <Button
                    type='primary'
                    style={{ backgroundColor: '#2e3d62', color: '#b59a63' }}
                    // onClick={redirectToReview}
                    shape='round'
                    onClick={showDrawer}
                >
                    Pay Now
                </Button>
            </div>
            <Drawer
                title='Select the payment method'
                placement={'bottom'}
                closable={false}
                onClose={onClose}
                open={open}
                key={'bottom'}
                height={'250px'}
            >
                <PaymentOptions />
            </Drawer>
        </>
    );
};
const PaymentOptions = () => {
    const navigate = useNavigate();
    return (
        <div className='payment-container'>
            <div class='options-wrapper'>
                <div
                    class='payment-option card animate'
                    onClick={() => navigate('/thank-you')}
                >
                    <img
                        src='https://pvr-app.s3.ap-south-1.amazonaws.com/assets/debit-credit-payment.png'
                        alt='icon'
                        class='payment-option-img'
                    />
                    <p class='option-title'>Debit/Credit Card</p>
                </div>
                <div
                    class='payment-option upi animate'
                    onClick={() => navigate('/thank-you')}
                >
                    <img
                        src='https://pvr-app.s3.ap-south-1.amazonaws.com/assets/upi-payment.png'
                        alt='icon'
                        class='payment-option-img'
                    />
                    <p class='option-title'>UPI</p>
                </div>{' '}
            </div>
        </div>
    );
};

export default ReviewOrder;
