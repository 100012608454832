import { Button, Select } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { commonSelector, updateLoading } from '../store/slices/commonSlice';

const Home = () => {
    const { loading } = useSelector(commonSelector);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    console.log(loading);
    useEffect(() => {
        dispatch(updateLoading(true));
    }, []);

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };
    const handleGetStartedClick = (type) => {
        navigate(`/${type}`);
    };
    const res = Math.floor(Math.random() * 4);
    return (
        <div className={`homepage-wrapper one-${res}`}>
            <div className='logo'></div>
            <div className='hero-image'>
                <div className='shape-designs'>
                    <div className='shape shape-1'></div>
                    <div className='shape shape-2'></div>
                </div>
            </div>
            {/* <div className='get-started'>
                <div className='title'>
                    Weekly meal <span>plan</span>
                </div>
                <div className='subtitle'>
                   Enter these details and Continue
                </div>
                <div className='forminput-wrapper'>
                    <Select
                        placeholder='Select your vendor'
                        style={{ width: '100%' }}
                        size='large'
                        onChange={handleChange}
                        defaultValue='Tata Chemicals'
                        options={[
                            { value: 'tatachemicals', label: 'Tata Chemicals' },
                        ]}
                    />
                </div>
                <div className='forminput-wrapper'>
                    <Select
                        placeholder='Select your location'
                        style={{ width: '100%' }}
                        size='large'
                        onChange={handleChange}
                        defaultValue='mumbai'
                        options={[
                            { value: 'pune', label: 'Pune' },
                            { value: 'bangalore', label: 'Bangalore' },
                            { value: 'delhi', label: 'Delhi' },
                            { value: 'mumbai', label: 'Mumbai' },
                        ]}
                    />
                </div>

                <Button
                    type='primary'
                    block
                    shape='round'
                    size='large'
                    style={{ backgroundColor: '#1d1e22', color: '#b59a63' }}
                    onClick={handleGetStartedClick}
                >
                    Continue
                </Button>
            </div> */}
            <div className='mealtype-choices'>
                <div className='title'>Choose the menu <br />you would like to explore!</div>
                <div className='mealtype-card view-only' onClick={()=> handleGetStartedClick('view-only-menu')}>
                    <div className='icon'></div>
                    <div className='mealname-wrapper'>
                        <div className='meal-type-title'>View Only Menus</div>
                        <div className='meal-type-desp'>
                            The list of menu items will be in a view only mode.
                            you can see the mealkit for item but can't order
                            them.
                        </div>
                        <div className='arrow-icon'></div>
                    </div>
                </div>
                <div className='mealtype-card transactional' onClick={()=> handleGetStartedClick('meal-preference')}>
                    <div className='icon'></div>
                    <div className='mealname-wrapper'>
                        <div className='meal-type-title'>
                            Transactional Menus
                        </div>
                        <div className='meal-type-desp'>
                            The list of menu items will be in a transactional
                            only mode. you can order the mealkit of all the
                            items.
                        </div>
                        <div className='arrow-icon'></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
