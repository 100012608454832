import { Button, Dropdown } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import MealCard from '../components/MealCard';
import { BREAKFAST, DINNER, LUNCH } from '../typecodes';
import { CommentOutlined, CustomerServiceOutlined } from '@ant-design/icons';
import { FloatButton } from 'antd';
const MenuItemsList = ({ activetab }) => {
    const navigate = useNavigate();
    const redirectToReview = () => {
        navigate('/review-order');
    };
    let data = [];
    if (activetab === 'breakfast') {
        data = BREAKFAST;
    } else if (activetab === 'lunch') {
        data = LUNCH;
    } else {
        data = DINNER;
    }
    const items = [
        {
            key: '1',
            label: (
                <a
                    rel='noopener noreferrer'
                    href='#sec-0'
                >
                    Breakfast
                </a>
            ),
        },
        {
            key: '2',
            label: (
                <a
                rel='noopener noreferrer'
                href='#sec-1'
                >
                    Lunch
                </a>
            ),
        },
        {
            key: '3',
            label: (
                <a
                rel='noopener noreferrer'
                href='#sec-0'
                >
                    Dinner
                </a>
            ),
        },
    ];
    return (
        <>
            <div className='menuitem-listing-wrapper'>
                {data.map((item,index) => {
                    return (
                        <div className='item-categories' id={`sec-${index}`}>
                            <div className='category-name'>{item.name}</div>
                            <div className='item-list-container'>
                                <MealCard item={item} />
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className='review-wrapper'>
                <div className='total-amt'>
                    Total <br />
                    <span>₹ 800.70</span>
                </div>
                <Button
                    type='primary'
                    style={{ backgroundColor: '#1d1e22', color: '#b59a63' }}
                    shape='round'
                    onClick={redirectToReview}
                >
                    Checkout
                </Button>
            </div>
            <div className='floatbtn'>
                <Dropdown
                    menu={{
                        items,
                    }}
                    placement='topRight'
                >
                    <Button type='primary' 
                    style={{ backgroundColor: '#1d1e22', color: '#b59a63' }}
                    shape='round'>Menu</Button>
                </Dropdown>
            </div>
        </>
    );
};

export default MenuItemsList;
