import { Route, Routes } from 'react-router-dom';
import DaysList from './pages/DaysList';
import Home from './pages/Home';
import MealPreferences from './pages/MealPreferences';
import MenuItemsList from './pages/MenuItemsList';
import ReviewOrder from './pages/ReviewOrder';
import ThankYou from './pages/ThankYou';
import ViewOnlyMenu from './pages/ViewOnlyMenu';

function App() {
    return (
        <Routes>
            <Route path='/' exact element={<Home />}></Route>
            <Route
                path='/meal-preference'
                exact
                element={<MealPreferences />}
            ></Route>
             <Route
                path='/view-only-menu'
                exact
                element={<ViewOnlyMenu />}
            ></Route>
            
            <Route path='/select-day' exact element={<DaysList />}></Route>
            <Route path='/menu-items' exact element={<MenuItemsList />}></Route>
            <Route path='/review-order' exact element={<ReviewOrder />}></Route>
            <Route path='/thank-you' exact element={<ThankYou />}></Route>

            {/* <Route path='/not-found' exact={true} element={<NotFound />} />
            <Route path='*' exact={true} element={<NotFound />} /> */}
        </Routes>
    );
}

export default App;
