import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';

const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        updateLoading(state, { payload }) {
            state.loading = payload;
        },
        updateError(state, { payload }) {
            state.error = payload;
        },
    },
});

export const { updateError, updateLoading } = commonSlice.actions;
export const commonSelector = (state) => state.commonSlice;
export default commonSlice.reducer;
