export const BREAKFAST = [
    {
        id: 3565,
        menu_id: 505,
        name: 'HEALTHY BEGINNINGS',
        mealName: 'AMERICAN BREAKFAST',
        description:
            '<p><strong>Choice of Fresh Juice</strong></p><p>Orange, guava, pineapple, watermelon, carrot or tomato&nbsp;</p><p><strong>Baker’s Basket (Choice of Three Items) (Contains nuts and dairy)</strong></p><p>White toast, brown toast, fruit Danish, almond Danish, low fat muffin, fruit muffins, soft roll and croissant</p><p><strong>Choice of 2 Preserves and Spread</strong></p><p>Salted butter, butter, margarine, peanut butter, honey, nutella, strawberry jam, orange marmalade</p></p>',
        image: 'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-items/1681989832-Picture6.png',
        tags: null,
        enabled: 1,
        created_at: '2020-10-15T07:41:24.000000Z',
        updated_at: '2022-06-30T07:29:20.000000Z',
        priority: 0,
        deleted_at: null,
        sub_categories: [],
        without_sub_category_items: [
            {
                id: 27596,
                menu_category_id: 3565,
                menu_sub_category_id: null,
                name: 'BEETROOT POMEGRANATE APPLE JUICE',
                description: 'Selection of sliced seasonal fruits',
                tags: null,
                enabled: 0,
                created_at: '2020-10-15T07:41:24.000000Z',
                updated_at: '2022-06-04T07:02:51.000000Z',
                price: 350,
                thumbnail:
                    'https://pvr-app.s3.ap-south-1.amazonaws.com/images/49f580e6-d496-4877-a008-ae7c29b2941a-Beetroot---Promogranate-Juice-554px.jpg',
                type: '',
                max_per_order: 3,
                min_per_order: 1,
                menu_id: 505,
                tax: 0,
                priority: 0,
                in_kitchen_time: 45,
                display_type: 'small_thumbnail',
                deleted_at: null,
                is_deliverable: 1,
                is_take_away: 1,
                is_healthy: 0,
                taxable: 1,
                sub_addons: [],
                without_subaddon_addons: [],
                taxes: [],
            },
            {
                id: 27597,
                menu_category_id: 3565,
                menu_sub_category_id: null,
                name: 'ORANGE JUICE',
                description: null,
                tags: '[]',
                enabled: 1,
                created_at: '2020-10-15T07:41:24.000000Z',
                updated_at: '2022-07-04T06:16:44.000000Z',
                price: 450,
                thumbnail:
                    'https://pvr-app.s3.ap-south-1.amazonaws.com/images/51a52765-d711-4106-8e92-58362d0de754-ORANGE-JUICE-554px.jpg',
                type: 'veg',
                max_per_order: 3,
                min_per_order: 1,
                menu_id: 505,
                tax: 0,
                priority: 0,
                in_kitchen_time: 45,
                display_type: '',
                deleted_at: null,
                is_deliverable: 1,
                is_take_away: 1,
                is_healthy: 0,
                taxable: 1,
                sub_addons: [
                    {
                        id: 10068,
                        menu_item_id: 27597,
                        name: 'Choice of  Fruits in Juice',
                        description: null,
                        tags: null,
                        enabled: 1,
                        max_addon_per_order: 4,
                        min_addon_per_order: 1,
                        type: 'single_select',
                        created_at: '2020-10-15T07:41:24.000000Z',
                        updated_at: '2020-12-06T07:19:50.000000Z',
                        priority: 0,
                        deleted_at: null,
                        addons: [
                            {
                                id: 34692,
                                menu_item_id: 27597,
                                name: 'Watermelon',
                                description: null,
                                tags: null,
                                enabled: 1,
                                created_at: '2020-10-15T07:41:24.000000Z',
                                updated_at: '2020-10-15T07:41:24.000000Z',
                                thumbnail: null,
                                price: 0,
                                type: 'veg',
                                menu_item_subaddon_id: 10068,
                                max_per_order: 1,
                                min_per_order: 0,
                                tax: 0,
                                priority: 0,
                                deleted_at: null,
                                taxable: 1,
                                taxes: [],
                            },
                            {
                                id: 34693,
                                menu_item_id: 27597,
                                name: 'Pineapple',
                                description: null,
                                tags: null,
                                enabled: 1,
                                created_at: '2020-10-15T07:41:24.000000Z',
                                updated_at: '2020-10-15T07:41:24.000000Z',
                                thumbnail: null,
                                price: 0,
                                type: 'veg',
                                menu_item_subaddon_id: 10068,
                                max_per_order: 1,
                                min_per_order: 0,
                                tax: 0,
                                priority: 0,
                                deleted_at: null,
                                taxable: 1,
                                taxes: [],
                            },
                            {
                                id: 34694,
                                menu_item_id: 27597,
                                name: 'Orange',
                                description: null,
                                tags: null,
                                enabled: 1,
                                created_at: '2020-10-15T07:41:24.000000Z',
                                updated_at: '2020-10-15T07:41:24.000000Z',
                                thumbnail: null,
                                price: 0,
                                type: 'veg',
                                menu_item_subaddon_id: 10068,
                                max_per_order: 1,
                                min_per_order: 0,
                                tax: 0,
                                priority: 0,
                                deleted_at: null,
                                taxable: 1,
                                taxes: [],
                            },
                            {
                                id: 34695,
                                menu_item_id: 27597,
                                name: 'Apple',
                                description: null,
                                tags: null,
                                enabled: 1,
                                created_at: '2020-10-15T07:41:24.000000Z',
                                updated_at: '2020-10-15T07:41:24.000000Z',
                                thumbnail: null,
                                price: 0,
                                type: 'veg',
                                menu_item_subaddon_id: 10068,
                                max_per_order: 1,
                                min_per_order: 0,
                                tax: 0,
                                priority: 0,
                                deleted_at: null,
                                taxable: 1,
                                taxes: [],
                            },
                        ],
                    },
                ],
                without_subaddon_addons: [],
                taxes: [],
            },
            {
                id: 27598,
                menu_category_id: 3565,
                menu_sub_category_id: null,
                name: 'RAW SUGARCANE JUICE',
                description: null,
                tags: null,
                enabled: 0,
                created_at: '2020-10-15T07:41:24.000000Z',
                updated_at: '2022-06-04T07:03:03.000000Z',
                price: 450,
                thumbnail:
                    'https://pvr-app.s3.ap-south-1.amazonaws.com/images/bcd36226-dbd8-4dc2-9e75-1541c655af49-RAW-SUGARCANE-JUICE-554px.jpg',
                type: 'veg',
                max_per_order: 4,
                min_per_order: 1,
                menu_id: 505,
                tax: 0,
                priority: 0,
                in_kitchen_time: 45,
                display_type: 'small_thumbnail',
                deleted_at: null,
                is_deliverable: 1,
                is_take_away: 1,
                is_healthy: 0,
                taxable: 1,

                without_subaddon_addons: [],
                taxes: [],
            },
        ],
    },
    {
        id: 3566,
        menu_id: 505,
        name: 'INDULGE',
        mealName: 'Smoked Salmon',
        description:
            '<p>Combination of Vietnamese fresh and deep fried spring rolls served with dipping sauce</p>',
        tags: null,
        enabled: 1,
        created_at: '2020-10-15T07:41:24.000000Z',
        updated_at: '2020-10-15T07:41:24.000000Z',
        image: 'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-items/1681989986-Picture8.png',
        priority: 0,
        deleted_at: null,
        sub_categories: [],
        without_sub_category_items: [
            {
                id: 27604,
                menu_category_id: 3566,
                menu_sub_category_id: null,
                name: 'Cheese Burger',
                description: null,
                tags: null,
                enabled: 1,
                created_at: '2020-10-15T07:41:24.000000Z',
                updated_at: '2020-10-15T07:41:24.000000Z',
                price: 300,
                thumbnail:
                    'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-items/1681990620-cheese%20burg1.jpg',
                type: 'non_veg',
                max_per_order: 3,
                min_per_order: 1,
                menu_id: 505,
                tax: 0,
                priority: 0,
                in_kitchen_time: 45,
                display_type: 'no_thumbnail',
                deleted_at: null,
                is_deliverable: 1,
                is_take_away: 1,
                is_healthy: 0,
                taxable: 1,
                sub_addons: [],
                without_subaddon_addons: [],
                taxes: [],
            },
            {
                id: 27605,
                menu_category_id: 3566,
                menu_sub_category_id: null,
                name: 'Two Eggs Cooked Upon Request',
                description: null,
                tags: null,
                enabled: 1,
                created_at: '2020-10-15T07:41:24.000000Z',
                updated_at: '2020-10-15T07:41:24.000000Z',
                price: 250,
                thumbnail:
                    'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-items/1681990332-pancke1.png',
                type: 'non_veg',
                max_per_order: 3,
                min_per_order: 1,
                menu_id: 505,
                tax: 0,
                priority: 0,
                in_kitchen_time: 45,
                display_type: 'small_thumbnail',
                deleted_at: null,
                is_deliverable: 1,
                is_take_away: 1,
                is_healthy: 0,
                taxable: 1,
                sub_addons: [],
                without_subaddon_addons: [],
                taxes: [],
            },
            {
                id: 27606,
                menu_category_id: 3566,
                menu_sub_category_id: null,
                name: 'All Egg White Omelette',
                description: null,
                tags: null,
                enabled: 1,
                created_at: '2020-10-15T07:41:24.000000Z',
                updated_at: '2020-10-15T07:41:24.000000Z',
                price: 350,
                thumbnail:
                    'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-items/1681990264-Picture10.png',
                type: 'non_veg',
                max_per_order: 3,
                min_per_order: 1,
                menu_id: 505,
                tax: 0,
                priority: 0,
                in_kitchen_time: 45,
                display_type: 'small_thumbnail',
                deleted_at: null,
                is_deliverable: 1,
                is_take_away: 1,
                is_healthy: 0,
                taxable: 1,
                sub_addons: [],
                without_subaddon_addons: [],
                taxes: [],
            },
        ],
    },
    {
        id: 3567,
        menu_id: 505,
        name: 'OVEN FRESH BAKERIES',
        mealName: 'BURGERS & SANDWICHES',
        description:
            '<p><b>Condiments</b><br>Tomato ketchup, chili sauce, mustard, mayonnaise, Tabasco sauce, HP sauce or A1 sauce</p>',
        image: 'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-items/1681989909-Picture7.png',
        tags: null,
        enabled: 1,
        created_at: '2020-10-15T07:41:24.000000Z',
        updated_at: '2020-10-15T07:41:24.000000Z',
        priority: 0,
        deleted_at: null,
        sub_categories: [],
        without_sub_category_items: [
            {
                id: 27608,
                menu_category_id: 3567,
                menu_sub_category_id: null,
                name: 'Smoked Salmon',
                description: 'Sour cream, caper and onion',
                tags: null,
                enabled: 1,
                created_at: '2020-10-15T07:41:24.000000Z',
                updated_at: '2020-10-15T07:41:24.000000Z',
                price: 450,
                thumbnail:
                    'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-items/1681990663-Picture13.png',
                type: 'non_veg',
                max_per_order: 3,
                min_per_order: 1,
                menu_id: 505,
                tax: 0,
                priority: 0,
                in_kitchen_time: 45,
                display_type: 'no_thumbnail',
                deleted_at: null,
                is_deliverable: 1,
                is_take_away: 1,
                is_healthy: 0,
                taxable: 1,
                sub_addons: [],
                without_subaddon_addons: [],
                taxes: [],
            },
        ],
    },
];
export const LUNCH = [
    {
        id: 3565,
        name: 'Starter',
        mealName: 'Dwaeji Galbi',
        description: '<p>Korean -style pork ribs with Ssamjang sauce</p>',
        image: 'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-items/1682055405-dwaeji%20galbi.png',
        tags: null,
        without_sub_category_items: [
            {
                name: 'Roasted Tomato Garlic Soup',
                description: 'Served with pesto oil and marinated feta cheese',
                price: 450,
                thumbnail:
                    'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-items/1681990501-soup12.jpg',
                display_type: 'small_thumbnail',
                type: 'veg',
            },
            {
                name: 'Vegetarian spring rolls',
                description:
                    'Combination of Vietnamese fresh and deep fried spring roll served with dipping sauce',
                price: 450,
                thumbnail:
                    'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-items/1682055583-spring%20rolls.png',
                display_type: 'small_thumbnail',
                type: 'veg',
            },
            {
                name: 'Kalbi Tang',
                description: 'US beef short rib soup',
                price: 450,
                thumbnail:
                    'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-items/1682055333-kalbi%20tang.png',
                display_type: 'small_thumbnail',
                type: 'non_veg',
            },
        ],
    },
    {
        name: 'Main Course',
        mealName: 'Grilled AUS Rack of Lamb',
        description:
            '<p>Gratin potato, fresh lily bulbs, oven roasted tomato, rosemary herb and green pea puree sauce</p>',

        image: 'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-items/1681990882-Picture15.png',

        without_sub_category_items: [
            {
                name: 'Traditional “La Vong” Fish',
                description:
                    'Fresh noodle, peanuts, lettuce, bean sprouts, herbs, chilli and fish sauce',
                price: 350,
                thumbnail:
                    'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-items/1681990812-la%20vong1.jpg',
                type: 'non_veg',
                display_type: 'small_thumbnail',
                taxes: [],
            },
            {
                id: 27605,
                menu_category_id: 3566,
                menu_sub_category_id: null,
                name: 'Two Eggs Cooked Upon Request',
                description: null,
                tags: null,
                enabled: 1,
                created_at: '2020-10-15T07:41:24.000000Z',
                updated_at: '2020-10-15T07:41:24.000000Z',
                price: 250,
                thumbnail:
                    'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-items/1681990332-pancke1.png',
                type: 'non_veg',
                max_per_order: 3,
                min_per_order: 1,
                menu_id: 505,
                tax: 0,
                priority: 0,
                in_kitchen_time: 45,
                display_type: 'small_thumbnail',
                deleted_at: null,
                is_deliverable: 1,
                is_take_away: 1,
                is_healthy: 0,
                taxable: 1,
                sub_addons: [],
                without_subaddon_addons: [],
                taxes: [],
            },
            {
                id: 27606,
                menu_category_id: 3566,
                menu_sub_category_id: null,
                name: 'All Egg White Omelette',
                description: null,
                tags: null,
                enabled: 1,
                created_at: '2020-10-15T07:41:24.000000Z',
                updated_at: '2020-10-15T07:41:24.000000Z',
                price: 350,
                thumbnail:
                    'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-items/1681990264-Picture10.png',
                type: 'non_veg',
                max_per_order: 3,
                min_per_order: 1,
                menu_id: 505,
                tax: 0,
                priority: 0,
                in_kitchen_time: 45,
                display_type: 'small_thumbnail',
                deleted_at: null,
                is_deliverable: 1,
                is_take_away: 1,
                is_healthy: 0,
                taxable: 1,
                sub_addons: [],
                without_subaddon_addons: [],
                taxes: [],
            },
        ],
    },
    {
        name: 'Desert',
        mealName: 'PASSION CHEESECAKE',
        description:
            '<p>Passion sauce and chocolate décor Nước sốt chanh dây và trang trí sô cô la</p>',
        image: 'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-categories/Dessert.jpg',

        without_sub_category_items: [
            {
                name: 'GULAB JAMUN',
                description:
                    'Cottage cheese dumplings soaked in a saffron syrup',
                thumbnail:
                    'https://media.istockphoto.com/id/521803129/photo/gulab-jamun-11.jpg?s=612x612&w=0&k=20&c=wyJaXY7Uu0hMBCXkcTDRaujSKN6Rp9roJeKDvF6CfHI=',
                price: 150,
                type: '',
                display_type: 'small_thumbnail',
            },
            {
                name: 'GULAB KI KHEER',
                description:
                    'Indian rice pudding finished with fragrant rose petals and nuts',
                thumbnail:
                    'https://c.ndtvimg.com/2019-05/qdvtotn8_kheer_625x300_02_May_19.jpg',
                price: 250,
                type: '',
                display_type: 'small_thumbnail',
            },
            {
                name: '66% VALRHONA FLOURLESS CAKE',
                description: 'Finished with shaved chocolate',
                thumbnail:
                    'https://cdn.valrhona-chocolate.com/pub/media/wysiwyg/recipes/IMG_6828_2_.JPG',
                price: 350,
                type: '',
                display_type: 'small_thumbnail',
            },
        ],
    },
];
export const DINNER = [
    {
        name: 'BITES',
        mealName: 'AMERICAN BREAKFAST',
        description:
            '<p>Butter head lettuce, tomato, caramelized onion, BBQ sauce, blue cheese mayo, brioche</p>',
        image: 'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-categories/1654044063-Resep-Sate-kambing.jpg',

        without_sub_category_items: [
            {
                name: 'MIE GORENG SEMINYAK',
                description:
                    'Fried noodle, chicken sate, mix vegetables, fried chicken, fried egg',
                tags: null,
                enabled: 0,
                created_at: '2020-10-15T07:41:24.000000Z',
                updated_at: '2022-06-04T07:02:51.000000Z',
                price: 350,
                thumbnail:
                    'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-categories/1654044313-plxnsky1xqbcknihjh5m.jpg',
                display_type: 'small_thumbnail',
            },
            {
                name: 'GRILLED BBQ CHICKEN',
                price: 450,
                thumbnail:
                    'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-categories/1622884226-4-ayam-bakar-favorit-di-surbaya-mau-coba-1912125.jpg',
                type: 'non_veg',
            },
            {
                name: 'BUCKET OF LUMPIA',
                description: 'Vegetable spring rolls, homemade sweet chili dip',
                price: 450,
                thumbnail:
                    'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-categories/1622882004-IMG-20200921-WA0079.jpg',
                type: 'veg',
                display_type: 'small_thumbnail',
            },
        ],
    },
    {
        id: 3566,
        menu_id: 505,
        name: 'INDULGE',
        mealName: 'Smoked Salmon',
        description:
            '<p>Combination of Vietnamese fresh and deep fried spring rolls served with dipping sauce</p>',
      
        image: 'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-categories/1622885885-Sandwiches-hp-GQ-17Sep15_b.jpg',
        priority: 0,
       
        without_sub_category_items: [
            {
            
                name: 'Cheese Burger',
            
                price: 300,
                thumbnail:
                    'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-categories/1622883972-broccoli-4750593_960_720.jpg',
                type: 'veg',
                max_per_order: 3,
                min_per_order: 1,
                menu_id: 505,
                tax: 0,
                priority: 0,
                in_kitchen_time: 45,
                display_type: 'no_thumbnail',
                deleted_at: null,
                is_deliverable: 1,
                is_take_away: 1,
                is_healthy: 0,
                taxable: 1,
            },
            {
                id: 27605,
                menu_category_id: 3566,
                menu_sub_category_id: null,
                name: 'Two Eggs Cooked Upon Request',
                description: null,
                tags: null,
                enabled: 1,
                created_at: '2020-10-15T07:41:24.000000Z',
                updated_at: '2020-10-15T07:41:24.000000Z',
                price: 250,
                thumbnail:
                    'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-categories/1622885553-image.jpg',
                type: 'non_veg',
                max_per_order: 3,
                min_per_order: 1,
                menu_id: 505,
                tax: 0,
                priority: 0,
                in_kitchen_time: 45,
                display_type: 'small_thumbnail',
                deleted_at: null,
                is_deliverable: 1,
                is_take_away: 1,
                is_healthy: 0,
                taxable: 1,
            },
            {
                id: 27606,
                menu_category_id: 3566,
                menu_sub_category_id: null,
                name: 'PASTA de CUCINA',
                description: null,
                tags: null,
                enabled: 1,
                created_at: '2020-10-15T07:41:24.000000Z',
                updated_at: '2020-10-15T07:41:24.000000Z',
                price: 350,
                thumbnail:
                    'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-categories/1622885586-5ed358167e804.jpg',
                type: 'veg',
                max_per_order: 3,
                min_per_order: 1,
                menu_id: 505,
                tax: 0,
                priority: 0,
                in_kitchen_time: 45,
                display_type: 'small_thumbnail',
                deleted_at: null,
                is_deliverable: 1,
                is_take_away: 1,
                is_healthy: 0,
                taxable: 1,
                sub_addons: [],
                without_subaddon_addons: [],
                taxes: [],
            },
        ],
    },
    {
        id: 3567,
        menu_id: 505,
        name: 'BETWEEN THE BREAD',
        mealName: 'BLOODY MARRY',
        description:
            '<p>Served with fries;  gluten free bread available on request</p>',
        image: 'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-categories/1622884226-4-ayam-bakar-favorit-di-surbaya-mau-coba-1912125.jpg',
     
        without_sub_category_items: [
            {
               
                name: 'TRUFFLE PARMESAN FRIES',
                description: 'Truffle oil, grated parmesan cheese',
                price: 450,
                thumbnail:
                    'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-categories/1622882286-IMG-20200921-WA0067.jpg',
                type: 'veg',
                display_type: 'no_thumbnail',
                taxes: [],
            },
        ],
    },
];
export const VIEWONLY1 = [
    {
        name: 'Salad',
        mealName: 'Salad',
        description:
            '<p>Butter head lettuce, tomato, caramelized onion, BBQ sauce, blue cheese mayo, brioche</p>',
        image: 'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-categories/1654044063-Resep-Sate-kambing.jpg',

        without_sub_category_items: [
            {
                name: 'Sundal',
                price: 450,
                thumbnail:
                    'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-categories/1622884226-4-ayam-bakar-favorit-di-surbaya-mau-coba-1912125.jpg',
                type: 'non_veg',
                description:
                '<p>Butter head lettuce, tomato, caramelized onion, BBQ sauce, blue cheese mayo, brioche</p>',
            },
           
        ],
    },
    {
        name: 'Non Veg Main with bone (150 gms)',
        mealName: 'Non Veg Main with bone (150 gms)',
        description:
            '<p>Butter head lettuce, tomato, caramelized onion, BBQ sauce, blue cheese mayo, brioche</p>',
        image: 'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-categories/1654044063-Resep-Sate-kambing.jpg',

        without_sub_category_items: [
            {
                name: 'Chicken Handi',
                price: 450,
                thumbnail:
                    'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-categories/1622884226-4-ayam-bakar-favorit-di-surbaya-mau-coba-1912125.jpg',
                type: 'non_veg',
                description:
                '<p>Butter head lettuce, tomato, caramelized onion, BBQ sauce, blue cheese mayo, brioche</p>',
            },
           
        ],
    },
    {
        name: 'Semi Dry Veg Main (120 gms)',
        mealName: 'Semi Dry Veg Main (120 gms)',
        description:
            '<p>Butter head lettuce, tomato, caramelized onion, BBQ sauce, blue cheese mayo, brioche</p>',
        image: 'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-categories/1654044063-Resep-Sate-kambing.jpg',

        without_sub_category_items: [
            {
                name: 'Methi Mutter Malai',
                price: 450,
                thumbnail:
                    'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-categories/1622884226-4-ayam-bakar-favorit-di-surbaya-mau-coba-1912125.jpg',
                type: 'non_veg',
                description:
                '<p>Butter head lettuce, tomato, caramelized onion, BBQ sauce, blue cheese mayo, brioche</p>',
            },
           
        ],
    },
    {
        name: 'Dry Veg Main (70 gms)',
        mealName: 'Dry Veg Main (70 gms)',
        description:
            '<p>Butter head lettuce, tomato, caramelized onion, BBQ sauce, blue cheese mayo, brioche</p>',
        image: 'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-categories/1654044063-Resep-Sate-kambing.jpg',

        without_sub_category_items: [
            {
                name: 'Cabbage Fugath',
                price: 450,
                thumbnail:
                    'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-categories/1622884226-4-ayam-bakar-favorit-di-surbaya-mau-coba-1912125.jpg',
                type: 'non_veg',
                description:
                '<p>Butter head lettuce, tomato, caramelized onion, BBQ sauce, blue cheese mayo, brioche</p>',
            },
            {
                name: 'Cabbage Fugath',
                price: 450,
                thumbnail:
                    'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-categories/1622884226-4-ayam-bakar-favorit-di-surbaya-mau-coba-1912125.jpg',
                type: 'non_veg',
                description:
                '<p>Butter head lettuce, tomato, caramelized onion, BBQ sauce, blue cheese mayo, brioche</p>',
            },
            {
                name: 'Cabbage Fugath',
                price: 450,
                thumbnail:
                    'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-categories/1622884226-4-ayam-bakar-favorit-di-surbaya-mau-coba-1912125.jpg',
                type: 'non_veg',
                description:
                '<p>Butter head lettuce, tomato, caramelized onion, BBQ sauce, blue cheese mayo, brioche</p>',
            },
           
        ],
    },
   
    
    {
        name: 'Dal (125 gms)',
        mealName: 'Dal (125 gms)',
        description:
            '<p>Butter head lettuce, tomato, caramelized onion, BBQ sauce, blue cheese mayo, brioche</p>',
        image: 'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-categories/1654044063-Resep-Sate-kambing.jpg',

        without_sub_category_items: [
            {
                name: 'Dal Makhani',
                price: 450,
                thumbnail:
                    'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-categories/1622884226-4-ayam-bakar-favorit-di-surbaya-mau-coba-1912125.jpg',
                type: 'non_veg',
                description:
                '<p>Butter head lettuce, tomato, caramelized onion, BBQ sauce, blue cheese mayo, brioche</p>',
            },
            {
                name: 'Dal Makhani',
                price: 450,
                thumbnail:
                    'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-categories/1622884226-4-ayam-bakar-favorit-di-surbaya-mau-coba-1912125.jpg',
                type: 'non_veg',
                description:
                '<p>Butter head lettuce, tomato, caramelized onion, BBQ sauce, blue cheese mayo, brioche</p>',
            },
            {
                name: 'Dal Makhani',
                price: 450,
                thumbnail:
                    'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-categories/1622884226-4-ayam-bakar-favorit-di-surbaya-mau-coba-1912125.jpg',
                type: 'non_veg',
                description:
                '<p>Butter head lettuce, tomato, caramelized onion, BBQ sauce, blue cheese mayo, brioche</p>',
            },
           
        ],
    },

    {
        name: 'Rice (150 gms)',
        mealName: 'Rice (150 gms)',
        description:
            '<p>Butter head lettuce, tomato, caramelized onion, BBQ sauce, blue cheese mayo, brioche</p>',
        image: 'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-categories/1654044063-Resep-Sate-kambing.jpg',

        without_sub_category_items: [
            {
                name: 'Steamed Rice',
                price: 450,
                thumbnail:
                    'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-categories/1622884226-4-ayam-bakar-favorit-di-surbaya-mau-coba-1912125.jpg',
                type: 'non_veg',
                description:
                '<p>Butter head lettuce, tomato, caramelized onion, BBQ sauce, blue cheese mayo, brioche</p>',
            },
            {
                name: 'Steamed Rice',
                price: 450,
                thumbnail:
                    'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-categories/1622884226-4-ayam-bakar-favorit-di-surbaya-mau-coba-1912125.jpg',
                type: 'non_veg',
                description:
                '<p>Butter head lettuce, tomato, caramelized onion, BBQ sauce, blue cheese mayo, brioche</p>',
            },
            {
                name: 'Steamed Rice',
                price: 450,
                thumbnail:
                    'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-categories/1622884226-4-ayam-bakar-favorit-di-surbaya-mau-coba-1912125.jpg',
                type: 'non_veg',
                description:
                '<p>Butter head lettuce, tomato, caramelized onion, BBQ sauce, blue cheese mayo, brioche</p>',
            },
           
        ],
    },
    {
        name: 'Indian Bread (2 nos)',
        mealName: 'Indian Bread (2 nos)',
        description:
            '<p>Butter head lettuce, tomato, caramelized onion, BBQ sauce, blue cheese mayo, brioche</p>',
        image: 'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-categories/1654044063-Resep-Sate-kambing.jpg',

        without_sub_category_items: [
            {
                name: 'Chapati',
                price: 450,
                thumbnail:
                    'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-categories/1622884226-4-ayam-bakar-favorit-di-surbaya-mau-coba-1912125.jpg',
                type: 'non_veg',
                description:
                '<p>Butter head lettuce, tomato, caramelized onion, BBQ sauce, blue cheese mayo, brioche</p>',
            },
            {
                name: 'Chapati',
                price: 450,
                thumbnail:
                    'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-categories/1622884226-4-ayam-bakar-favorit-di-surbaya-mau-coba-1912125.jpg',
                type: 'non_veg',
                description:
                '<p>Butter head lettuce, tomato, caramelized onion, BBQ sauce, blue cheese mayo, brioche</p>',
            },
            {
                name: 'Chapati',
                price: 450,
                thumbnail:
                    'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-categories/1622884226-4-ayam-bakar-favorit-di-surbaya-mau-coba-1912125.jpg',
                type: 'non_veg',
                description:
                '<p>Butter head lettuce, tomato, caramelized onion, BBQ sauce, blue cheese mayo, brioche</p>',
            },
           
        ],
    },
    {
        name: 'Dessert (50 gms)',
        mealName: 'Dessert (50 gms)',
        description:
            '<p>Butter head lettuce, tomato, caramelized onion, BBQ sauce, blue cheese mayo, brioche</p>',
        image: 'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-categories/1654044063-Resep-Sate-kambing.jpg',

        without_sub_category_items: [
            {
                name: 'Strawberry Phirni',
                price: 450,
                thumbnail:
                    'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-categories/1622884226-4-ayam-bakar-favorit-di-surbaya-mau-coba-1912125.jpg',
                type: 'non_veg',
                description:
                '<p>Butter head lettuce, tomato, caramelized onion, BBQ sauce, blue cheese mayo, brioche</p>',
            },   {
                name: 'Strawberry Phirni',
                price: 450,
                thumbnail:
                    'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-categories/1622884226-4-ayam-bakar-favorit-di-surbaya-mau-coba-1912125.jpg',
                type: 'non_veg',
                description:
                '<p>Butter head lettuce, tomato, caramelized onion, BBQ sauce, blue cheese mayo, brioche</p>',
            },   {
                name: 'Strawberry Phirni',
                price: 450,
                thumbnail:
                    'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-categories/1622884226-4-ayam-bakar-favorit-di-surbaya-mau-coba-1912125.jpg',
                type: 'non_veg',
                description:
                '<p>Butter head lettuce, tomato, caramelized onion, BBQ sauce, blue cheese mayo, brioche</p>',
            },
           
        ],
    },
    
   
];
