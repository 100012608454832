import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import MenuItemsList from './MenuItemsList';
import { generateWeekDays } from '../helpers/_commonHelper';
import CatIcon from '../assets/all.png';
const categories = [
    {
        id: 1,
        name: 'Breakfast',
        icon: '../assets/all.png',
    },
    {
        id: 1,
        name: 'Live Counter',
        icon: '../assets/cupcake.png',
    },
    {
        id: 1,
        name: 'Egg Dishses',
        icon: '../assets/drink.png',
    },
    {
        id: 1,
        name: 'Breakfast (Bakery)',
        icon: '../assets/hot.png',
    },
    {
        id: 1,
        name: 'Meal Combos',
        icon: '../assets/ramen.png',
    },
    {
        id: 1,
        name: 'Chinese',
        icon: '../assets/rasgulla.png',
    },
    {
        id: 1,
        name: 'Dessert',
        icon: '../assets/rasgulla1.png',
    },
    {
        id: 1,
        name: 'Sandwiches',
        icon: '../assets/all.png',
    },
    {
        id: 1,
        name: 'Burger / Patty',
        icon: '../assets/hot.png',
    },
    {
        id: 1,
        name: 'Snacks and Bites',
        icon: '../assets/cupcake.png',
    },
];
const DaysList = () => {
    const navigate = useNavigate();
    const list = generateWeekDays();
    const [activetab, setActiveTab] = useState('breakfast');
    const [daysList, setDaysList] = useState(list);
    const [selectedDate, setSelectedDate] = useState(list[0]?.Date || '');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const dayClickHandler = () => {
        navigate('/menu-items');
    };
    return (
        <>
            <Header />
            <div className='days_wrapper' >
                {daysList.map((day) => {
                    return (
                        <div
                            className={
                                'day ' +
                                (day.Date === selectedDate ? 'active' : '')
                            }
                            onClick={() => setSelectedDate(day.Date)}
                        >
                            {day.dayShortName}
                            {/* <div className='date'>{day.Date}</div> */}
                        </div>
                    );
                })}
            </div>
            {/* <div className='time-tabs'>
                <div
                    className={
                        'tab tab-1 ' +
                        (activetab === 'breakfast' ? 'active' : '')
                    }
                    onClick={() => setActiveTab('breakfast')}
                >
                    Breakfast
                </div>
                <div
                    className={
                        'tab tab-1 ' + (activetab === 'lunch' ? 'active' : '')
                    }
                    onClick={() => setActiveTab('lunch')}
                >
                    Lunch
                </div>
                <div
                    className={
                        'tab tab-1 ' + (activetab === 'dinner' ? 'active' : '')
                    }
                    onClick={() => setActiveTab('dinner')}
                >
                    Dinner
                </div>
            </div> */}
            <div className='categories-container'>
                <div className='cat-title'>Categories</div>
                <div className='categories-wrapper'>
                    {categories.map((category) => (
                        <div className='category-card'>
                            <div className='icon-cat'>
                                <img src={CatIcon} alt='category-icon' />
                            </div>
                            <div className='cat-name'>{category.name}</div>
                        </div>
                    ))}
                </div>
            </div>
            <MenuItemsList activetab={activetab} />
            {/* <div className='days-wrapper'>
                <div className='page-title'>
                    Select the day for which you want to customize your meal!
                </div>
                <div className='days-container'>
                    <div className='day day-1' onClick={dayClickHandler}>
                        <div className='day-title'>Monday</div>
                    </div>
                    <div className='day day-1' onClick={dayClickHandler}>
                        <div className='day-title'>Tuesday</div>
                    </div>
                    <div className='day day-1' onClick={dayClickHandler}>
                        <div className='day-title'>Wednesday</div>
                    </div>
                    <div className='day day-1' onClick={dayClickHandler}>
                        <div className='day-title'>Thursday</div>
                    </div>
                    <div className='day day-1' onClick={dayClickHandler}>
                        <div className='day-title'>Friday</div>
                    </div>
                    <div className='day day-1' onClick={dayClickHandler}>
                        <div className='day-title'>Saturday</div>
                    </div>
                    <div className='day day-1' onClick={dayClickHandler}>
                        <div className='day-title'>Sunday</div>
                    </div>
                </div>
            </div> */}
        </>
    );
};

export default DaysList;
