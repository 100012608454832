import { Button, Result } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const ThankYou = () => {
    const navigate = useNavigate()
    return (
        <Result
            status='success'
            title='Successfully Order placed!'
            subTitle="Order number: 2017182818828182881! Thank you for ordering with us. We'll Keep you posted about your order!"
            extra={[
                <Button type='primary' key='console' onClick={()=>navigate("/")}
                style={{ backgroundColor: '#1d1e22', color: '#b59a63' }}
                
                >
                    Go to Home
                </Button>,
                <Button key='buy'  onClick={()=>navigate("/meal-preference")}>Explore Menu</Button>,
            ]}
        />
    );
};

export default ThankYou;
