import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import React from 'react';
import Alergy1 from "../assets/alergy/allergen.png"
import Alerg2 from "../assets/alergy/allergy.png"
import Alerg4 from "../assets/alergy/gluten-free.png"
import Alerg5 from "../assets/alergy/no-milk.png"

const ItemCard = ({ menuItem, showCounter }) => {
    const number = Math.floor(Math.random() * 5);

    return (
        <div className='item-food-card'>
            <div className='item-details-wrapper small_thumbnail'>
                <div className='item-title-wrapper'>
                    <div className='item-title-price-wrapper'>
                        {menuItem.type && (
                            <div className={`icon-img ${menuItem.type}`}></div>
                        )}
                        <div className='item-title'>{menuItem.name}</div>
                        <div className='food-alergy'>
                    <img src={Alergy1} alt="icon" className='alergy-icon'/>
                    <img src={Alerg2} alt="icon" className='alergy-icon'/>
                    <img src={Alerg4} alt="icon" className='alergy-icon'/>
                    <img src={Alerg5} alt="icon" className='alergy-icon'/>
                </div>
                        <div className='item-price'>₹ {menuItem.price}</div>
                        {menuItem.description && (
                            <div
                                className='item-description'
                                dangerouslySetInnerHTML={{
                                    __html: menuItem.description,
                                }}
                            ></div>
                        )}
                    </div>
                </div>
                <div className='item-thumbnail'>
                    <img
                        src={menuItem.thumbnail}
                        alt={menuItem.name}
                        className='item-image'
                    />
                    <div className='item-actions'>
                        {showCounter ? (
                            <div class='actions'>
                                <div class='counter'>
                                    <button class='icon'>
                                        <MinusOutlined />
                                    </button>
                                    <div class='icon count'>1</div>
                                    <button class='icon'>
                                        <PlusOutlined />
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <button className='btn btn-add-item'>
                                <PlusOutlined /> Add
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ItemCard;
