import React from 'react';
import ItemCard from './ItemCard';
import Alergy1 from "../assets/alergy/allergen.png"
import Alerg2 from "../assets/alergy/allergy.png"
import Alerg4 from "../assets/alergy/gluten-free.png"
import Alerg5 from "../assets/alergy/no-milk.png"

const itemArr = [
    '<p>Two eggs any style, served with hash browns, grilled bacon and breakfast sausages</p><p>Juice<br />choice of one item<br />apple / cranberry / mango / orange / pineapple / tomato</p><p>Hot Beverage<br />choice of one item<br />tea: chamomile / earl grey / english breakfast / green tea / peppermint<br />or<br />freshly brewed coffee / decaffeinated coffee / espresso / hot chocolate / latte / cappuccino<br />hot beverages are served with a choice of whole / low fat / soy milk/ almond milk - hot or cold</p>',
    '<p>CHICKEN 65 195 Kcal<br />Deep fried chicken morsels flavored with curry leaves and chili</p><p>CHICKEN NUGGETS 192 Kcal<br />Flavored chicken morsels breaded and deep-fried</p><p>VEGETABLE FINGER 152 Kcal<br />Mix vegetable strips breaded and deep-fried</p><p>CORN CHEESE NUGGETS 165 Kcal<br />Corn and cheese fritters</p><p>VEG PIZZA POCKETS 195 Kcal<br />Vegetable and cheese filled pizza fritters</p>',
    '<p><strong>AMUSE BOUCHE</strong><br />Seasonal fruit Kefir</p><p><strong>DELECTABLE TOMATO</strong><br />Confit tomato, lacto-fermented tomato juice, basil oil &amp; tomato powder</p><p><strong>ENERGY BOWL</strong><br />Pickled cucumber, edamame, buckwheat, beetroot avocado, tempeh, orange &amp; turmeric dressing</p><p><strong>WATERMELON TATAKI</strong><br />Watermelon, scallion, pickled shallots, pickled cucumber, sesame seeds &amp; chives oil</p>',
];
const MealCard = ({ item }) => {
    return (
        <div className='mealcard'>
            <div
                className='meal-img'
                style={{ backgroundImage: `url(${item.image})` }}
            ></div>
            <div className='meal-details'>
                <div className='meal-title'>{item.mealName}</div>
                <div className='food-alergy'>
                    <img src={Alergy1} alt="icon" className='alergy-icon'/>
                    <img src={Alerg2} alt="icon" className='alergy-icon'/>
                    <img src={Alerg4} alt="icon" className='alergy-icon'/>
                    <img src={Alerg5} alt="icon" className='alergy-icon'/>
                </div>
                <div
                    className='meal-description'
                    dangerouslySetInnerHTML={{ __html: item.description }}
                ></div>
               
                {item.without_sub_category_items.map((menuItem) => (
                    <ItemCard menuItem={menuItem} />
                ))}
            </div>
        </div>
    );
};

export default MealCard;
